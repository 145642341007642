import React from 'react'

import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <h1>Hi I'm Ido</h1>
    <p>Welcome to my Gatsby site.</p>
    <p>Now go build something great.</p>
  </Layout>
)

export default IndexPage
